import RedirectAuthed from 'components/RedirectAuthed'
import RedirectUnauthed from 'components/RedirectUnauthed'

export default function Custom404() {
  return (
    <RedirectUnauthed to="/login">
      <RedirectAuthed to="/" />
    </RedirectUnauthed>
  )
}
