import LoadingFullscreen from 'components/LoadingFullscreen'
import useAuth, { AuthStatus } from 'hooks/useAuth'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import React from 'react'
import { useEffect } from 'react'

export default function RedirectAuthed({ to = '/', children }) {
  const { authStatus } = useAuth()
  const router = useRouter()

  useEffect(() => {
    authStatus === AuthStatus.LOGGED_IN && router.push(to)
  }, [authStatus, router, to])

  if (authStatus === AuthStatus.UNINITIALIZED) return <LoadingFullscreen />
  if (authStatus === AuthStatus.LOGGED_IN) return null
  return children
}
RedirectAuthed.propTypes = { to: PropTypes.string }
